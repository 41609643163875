<template>
  <div class="sidebar-filters">
    <b-button class="text-uppercase" ref="escape" variant="reset-all" @click.prevent="resetFilters()">
      <i class="fas fa-eraser" /> {{ 'Reset all filters' | translate }}
    </b-button>
    <status-list-filter
      @edit="editComponentFilters($event, 'filter__status__in')"
      @clean="cleanComponentFilters(['filter__status__in'])"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as UNIT_TYPE_TYPES } from './store'
import CommonFiltersMixin from '../../common-filters/CommonFiltersMixin'

export default {
  name: 'UnitTypeCommonFilters',
  mixins: [CommonFiltersMixin],
  computed: {
    ...mapGetters({
      filters: UNIT_TYPE_TYPES.GENERIC.product.unittype.LIST.GETTERS.filters
    })
  },
  methods: {
    ...mapActions({
      ...UNIT_TYPE_TYPES.GENERIC.product.unittype.LIST.ACTIONS
    })
  }
}
</script>
