<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as UNIT_TYPE_TYPES } from './store'
import { ROUTES as UNIT_TYPE_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import UnitTypeTable from './UnitTypeTable'
import UnitTypeCommonFilters from './UnitTypeCommonFilters'
import UnitTypeEdit from './UnitTypeEdit.vue'

export default {
  name: 'UnitTypeList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': UnitTypeTable,
    'common-filters': UnitTypeCommonFilters,
    'component-edit': UnitTypeEdit,
    'component-detail': UnitTypeEdit
  },
  data () {
    return {
      UNIT_TYPE_ROUTES,
      title: this.$t('Unit type'),
      rolePerm: ['product_unittype_list'],
      fieldName: 'code',
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...UNIT_TYPE_TYPES.GENERIC.product.unittype.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.UNIT_TYPE_ROUTES.UNIT_TYPE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('product_unittype_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: UNIT_TYPE_TYPES.GENERIC.product.unittype.LIST.MUTATIONS.setSelectedList,
      setSelectAll: UNIT_TYPE_TYPES.GENERIC.product.unittype.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...UNIT_TYPE_TYPES.GENERIC.product.unittype.LIST.ACTIONS
    })
  }
}
</script>
